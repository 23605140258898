import $ from 'jquery';
import skipLinks from './utils/skipLinks';
import iframer from './utils/iframer';
// import closeNotification from './utils/closeNotification';
import mNav from './utils/mNav';
// import childTabIndex from './utils/childTabIndex';
// import searchExpand from './utils/searchExpand';
// import setupSharing from './utils/social';
// import equalHeight from './utils/equalHeight';
// import listShowHide from './utils/listShowHide';
import googleMaps from './utils/googleMaps';
// import removeNovalidate from './utils/removeNovalidate';
import multiNav from './utils/multiNav';
// import modalVideo from './utils/modalVideo';
// import infoButtons from './utils/infoButtons';
import homeHero from './utils/homeHero';
import modalHelp from './utils/modalHelp';
import printDetails from './utils/printDetails';
// import copyText from './utils/copyText';
// import printDiv from './utils/printDiv';
import scrollTop from './utils/scrollTop';

function globals () {


    // modalVideo
    // modalVideo('buttonPlay','modal','modalClose');
    // modalVideo('#buttonPlay','#modal','#modalClose');

	// Scroll top
	scrollTop('#top_button');

    // Print details
    printDetails();

    // iframe video in body content
    iframer();

    // Small Screen Navigation
    mNav(
        '#navigation-primary-toggle',
        'navigation-primary-toggle--open',
        '#navigation-primary__items',
        'navigation-primary__items--open'
    );

    // Multi Navigation
    multiNav(
        '#navigation-primary',
    );

    // Set child tab index for nav
    // childTabIndex(
    //     '#navigation-primary-toggle',
    //     '#navigation-primary',
    //     'navigation-primary--active',
    //     960
    // );

    // Filter showHide
    mNav(
        '#filter-trigger',
        'filter__heading--open',
        '#filter-reveal',
        'filter__reveal--open'
    );

    // // Search showHide
    // mNav(
    //     '#header-search-toggle',
    //     'header-search-toggle--open',
    //     '#header-search',
    //     'header-search--open'
    // );

    // Search expand
    // searchExpand(
    //     '.js-search',
    //     '.js-search',
    //     'js-search--active'
    // );

    // Events filter
    // mNav(
    //     '.filterBlock__title',
    //     'active',
    //     '.filters__wrap',
    //     'active'
    // );

    // Set child tab index for nav
    // childTabIndex(
    //     '.filterBlock__title',
    //     '.filters__wrap',
    //     'active',
    //     780
    // );

    // social
    // setupSharing();

    // equal height chart titles
    // equalHeight('.l-2up, .l-3up, .l-4up, .l-2-3up', '.m-intro__subheading');

    // hide children after x items and show "view more" button
    // listShowHide();

    // google maps - fires on all instances of maps so handles multiples
    // googleMaps();

    // removes the novalidate attribute on comment forms to enable client side validation
    // removeNovalidate();

    // Info buttons to reveal content in modal
    // infoButtons('.button-info');

    // Home hero paralax
    homeHero();

    // Modal help
    // modalHelp(trigger,target,targetClose);
    modalHelp('#button-info-difficulty', '#modal-info-difficulty', '.modal__close');
    modalHelp('#button-info-mobility', '#modal-info-mobility', '.modal__close');

    // Sharing copy url
    // copyText(
    //     '.js-copy-url',
    //     '.js-copy-trigger'
    // );

    // Enable switching to tab if it's in the URL and that's shared with someone
    $(document).ready(function(){
		if( window.location.hash != "" ) {
			$(window.location.hash).click();
		}
		$('#list-tab').click(function (e) {
			history.pushState(null,null,'#list-tab');
		});
		$('#map-tab').click(function (e) {
			history.pushState(null,null,'#map-tab');
		});
	});
}

$(function run () {
    console.log('ᕕ( ᐛ )ᕗ Running...');
    globals();
});
