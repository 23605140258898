import $ from 'jquery';

export default function scrollTop (trigger) {

    $(trigger).on('click', function(e) {
        e.preventDefault();
        document.body.scrollTop = 0; // For Safari
  		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });

    $(window).on('scroll', function(e) {
    	e.preventDefault();
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			$(trigger).fadeIn();
		} else {
			$(trigger).fadeOut();
		}
    });
}